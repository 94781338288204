import { Grid } from "@mui/material";

import { graphql } from "@/gql";
import { OverviewPage_SupplierFragment } from "@/gql/graphql";
import { usePermission } from "@/lib/permissions";

import { MainColumn } from "./MainColumn/MainColumn";
import { SideColumn } from "./SideColumn";

graphql(`
    fragment OverviewPage_Supplier on Supplier {
        ...MainColumn_Supplier
        ...SideColumn_Supplier
    }
`);

interface OverviewPageProps {
    supplier?: OverviewPage_SupplierFragment;
    refetch: () => void;
    loading: boolean;
}

export const OverviewPage: React.FC<OverviewPageProps> = ({ supplier, refetch, loading }) => {
    const isEditor = usePermission({ object: "general", relation: "write" });

    if (!supplier) return <></>;

    return (
        <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item md={12} lg={7} xl={8}>
                <MainColumn supplier={supplier} isEditor={isEditor} refetch={refetch} loading={loading} />
            </Grid>
            <Grid item md={12} lg={5} xl={4}>
                <SideColumn supplier={supplier} isEditor={isEditor} />
            </Grid>
        </Grid>
    );
};
