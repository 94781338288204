import { Box, Card, CardContent, Popover, Stack, Typography, styled } from "@mui/material";
import EmojiPicker, { Categories, Emoji, EmojiClickData } from "emoji-picker-react";
import { useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

export interface Actor {
    id: string;
    firstName: string;
    lastName?: string;
    fullName?: string;
    initials?: string;
}

export interface Emoji {
    code: string;
    clicked: boolean;
    count: number;
    actors: Actor[];
}

export function UpdateEmoji(emoji: Emoji, actor: Actor): Emoji {
    if (emoji.clicked) {
        return {
            ...emoji,
            count: emoji.count - 1,
            clicked: false,
            actors: emoji.actors.filter((curr) => curr.id !== actor.id),
        };
    }
    return {
        ...emoji,
        count: emoji.count + 1,
        clicked: true,
        actors: [...emoji.actors, actor],
    };
}

const BoxedEmoji = styled("div")<{ clicked: boolean }>(({ clicked }) => ({
    border: "1px solid lightgrey",
    borderRadius: "10%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "25px",
    gap: "5px",
    backgroundColor: clicked ? "rgb(234,239,249)" : "transparent",
}));

export const StyledEmoji: React.FC<{
    code: string;
    clicked?: boolean;
    count?: number;
    onClick?: () => void;
    popover?: boolean;
    actors: Actor[];
    currentUser: string;
}> = ({ code, clicked, count, onClick, popover, actors, currentUser }) => {
    const [emojiViewOpen, setEmojiViewOpen] = useState<boolean>(false);
    const emojiRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <BoxedEmoji
                sx={{
                    cursor: "pointer",
                }}
                clicked={clicked ?? false}
                onClick={onClick}
                onMouseEnter={() => {
                    setEmojiViewOpen(true);
                }}
                onMouseLeave={() => {
                    setEmojiViewOpen(false);
                }}
            >
                <div ref={emojiRef} style={{ display: "flex" }}>
                    <Emoji unified={code} size={20} />
                </div>
                <Typography variant="textSm">{count ?? 1}</Typography>
            </BoxedEmoji>
            {popover && (
                <EmojiPopover
                    emojiViewOpen={emojiViewOpen}
                    emojiRef={emojiRef}
                    actors={actors}
                    currentUser={currentUser ?? ""}
                    code={code}
                />
            )}
        </>
    );
};

const EmojiPopover: React.FC<{
    emojiViewOpen: boolean;
    emojiRef: React.RefObject<HTMLDivElement>;
    actors: Actor[];
    currentUser: string;
    code: string;
}> = ({ emojiViewOpen, emojiRef, actors, currentUser, code }) => {
    const { formatMessage } = useIntl();
    function setAnchorVerticalPosition(elementHeight: number | undefined, innerHeight: number) {
        if (!elementHeight) return "top";
        const spaceBelow = innerHeight - elementHeight > 400;
        if (spaceBelow) {
            return "bottom";
        }
        return "top";
    }

    function setElementVerticalPosition(elementHeight: number | undefined, innerHeight: number) {
        if (!elementHeight) return "top";
        const spaceBelow = innerHeight - elementHeight > 400;
        if (spaceBelow) {
            return "top";
        }
        return "bottom";
    }

    function generateMessage(actors: Actor[], currentUser: string) {
        if (actors.length === 1 && actors[0].id === currentUser) {
            return formatMessage({ defaultMessage: "You (click to remove) " }) + " ";
        } else if (actors.length === 1) {
            return actors[0].firstName + " ";
        }
        const actorNames = actors.map((actor) => {
            if (actor.id === currentUser) {
                return formatMessage({ defaultMessage: "You" });
            }
            return actor.firstName;
        });

        if (actorNames.length === 2) {
            return `${actorNames[0]} ${formatMessage({ defaultMessage: "and" })} ${actorNames[1]} `;
        }
        return `${actorNames.slice(0, -2).join(", ")}, ${actorNames[actorNames.length - 2]} ${formatMessage({ defaultMessage: "and" })} ${actorNames[actorNames.length - 1]} `;
    }

    return (
        <Popover
            style={{ pointerEvents: "none" }}
            open={emojiViewOpen}
            anchorEl={emojiRef.current}
            anchorOrigin={{
                vertical: setAnchorVerticalPosition(emojiRef.current?.getBoundingClientRect().top, window.innerHeight),
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: setElementVerticalPosition(emojiRef.current?.getBoundingClientRect().top, window.innerHeight),
                horizontal: "center",
            }}
            disableScrollLock
        >
            <Card>
                <CardContent
                    sx={{
                        maxWidth: "200px",
                    }}
                >
                    <Stack direction="column" justifyContent="center" alignItems="center">
                        <Box justifyContent="center" alignItems="center" pb={1} textAlign="center">
                            <Typography variant="textSm" fontWeight="bold">
                                {generateMessage(actors, currentUser)}
                            </Typography>
                            <Typography variant="textSm">
                                <FormattedMessage defaultMessage="reacted with: " />
                            </Typography>
                        </Box>
                        <Emoji unified={code} size={40} />
                    </Stack>
                </CardContent>
            </Card>
        </Popover>
    );
};

export const EmojiPickerPopover: React.FC<{
    setEmojiPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEmojis: React.Dispatch<React.SetStateAction<Emoji[]>>;
    emojiPickerOpen: boolean;
    emojiPickerAnchorEl: HTMLElement | null;
    HandleEmojiPickerInteraction: (emoji: EmojiClickData) => void;
}> = ({ setEmojiPickerOpen, emojiPickerOpen, emojiPickerAnchorEl, HandleEmojiPickerInteraction }) => {
    function setAnchorVerticalPosition(elementHeight: number | undefined, innerHeight: number) {
        if (!elementHeight) return "top";
        const spaceAbove = innerHeight - elementHeight < 800;
        if (spaceAbove) {
            return "top";
        }
        return "bottom";
    }

    function setElementVerticalPosition(elementHeight: number | undefined, innerHeight: number) {
        if (!elementHeight) return "top";
        const spaceAbove = innerHeight - elementHeight < 800;
        if (spaceAbove) {
            return "bottom";
        }
        return "top";
    }
    return (
        <Popover
            open={emojiPickerOpen}
            anchorEl={emojiPickerAnchorEl}
            onClose={() => setEmojiPickerOpen(false)}
            anchorOrigin={{
                vertical: setAnchorVerticalPosition(
                    emojiPickerAnchorEl?.getBoundingClientRect().top,
                    window.innerHeight
                ),
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: setElementVerticalPosition(
                    emojiPickerAnchorEl?.getBoundingClientRect().top,
                    window.innerHeight
                ),
                horizontal: 305,
            }}
            disableScrollLock
        >
            <EmojiPicker
                open={emojiPickerOpen}
                skinTonesDisabled
                previewConfig={{
                    showPreview: true,
                    defaultCaption: "Select an emoji",
                }}
                onEmojiClick={(emojiData) => HandleEmojiPickerInteraction(emojiData)}
                categories={useMemo(
                    () => [
                        {
                            category: Categories.SMILEYS_PEOPLE,
                            name: "Emojier og mennesker",
                        },
                        {
                            category: Categories.ANIMALS_NATURE,
                            name: "Dyr og natur",
                        },
                        {
                            category: Categories.FOOD_DRINK,
                            name: "Mat og drikke",
                        },
                        {
                            category: Categories.TRAVEL_PLACES,
                            name: "Reise og steder",
                        },
                        {
                            category: Categories.ACTIVITIES,
                            name: "Aktiviteter",
                        },
                        {
                            category: Categories.OBJECTS,
                            name: "Objekter",
                        },
                        {
                            category: Categories.SYMBOLS,
                            name: "Symboler",
                        },
                        {
                            category: Categories.FLAGS,
                            name: "Flagg",
                        },
                    ],
                    []
                )}
            />
        </Popover>
    );
};
