import { Plus } from "@ignite-analytics/icons";
import { Button, Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { Contracts_SupplierFragment } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { AllContractsModal } from "./AllContractsModal";
import { ContractItem } from "./ContractItem";

graphql(`
    fragment Contracts_Supplier on Supplier {
        id
        name
        contracts {
            id
            ...Contracts_Contract
        }
    }

    fragment Contracts_Contract on Contract {
        ...ContractItem_Contract
    }
`);

interface ContractsProps {
    supplier: Contracts_SupplierFragment;
    isEditor: boolean;
    loading: boolean;
}

export const Contracts: React.FC<ContractsProps> = ({ supplier, isEditor, loading }) => {
    const [openViewAll, setOpenViewAll] = useState(false);
    const navigate = useOutgoingNavigate();

    const handleAddContract = () => {
        track("Supplier Profile: Navigated Add Contract");
        const createContractSearchParams = encodeURIComponent(
            JSON.stringify({ open: true, supplier: { id: supplier.id, label: supplier.name } })
        );
        navigate(`/contracts/list?newContract=${createContractSearchParams}`);
    };

    const contracts = [...(supplier.contracts || [])];
    contracts.sort((a, b) => (a.endDate && b.endDate ? dayjs(b.endDate).diff(dayjs(a.endDate)) : -1));

    const enableViewAll = contracts.length > 3;

    return (
        <Stack spacing={2} pt={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="textXl" fontWeight={500}>
                    <FormattedMessage defaultMessage="Contracts" description="Contracts header" />
                </Typography>

                <Button color="secondary" size="2xsmall" disabled={!enableViewAll} onClick={() => setOpenViewAll(true)}>
                    <FormattedMessage defaultMessage="View all" description="View all documents button" />
                </Button>
            </Stack>

            {contracts.length > 0 ? (
                contracts.slice(0, 3).map((contract) => <ContractItem key={contract.id} contract={contract} />)
            ) : loading ? (
                <Card>
                    <CardContent>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack spacing={2}>
                                <Skeleton width="171px" height="17px" sx={{ borderRadius: 0.5 }} />
                                <Skeleton width="365px" height="17px" sx={{ borderRadius: 0.5 }} />
                            </Stack>
                            <Skeleton width="126px" height="40px" sx={{ borderRadius: 0.5 }} />
                        </Stack>
                    </CardContent>
                </Card>
            ) : (
                <Card sx={{ padding: 2, borderRadius: 1 }}>
                    <Typography variant="textSm" color={(theme) => theme.palette.primary.main}>
                        <FormattedMessage defaultMessage="No contracts found" />
                    </Typography>
                </Card>
            )}
            <Stack width="140px">
                <NoPermissionTooltip
                    hasPermission={isEditor}
                    placement="top"
                    message={<FormattedMessage defaultMessage="Your permissions do not allow adding a contract" />}
                >
                    <Button
                        disabled={!isEditor}
                        startIcon={<Plus />}
                        size="small"
                        color="linkPrimary"
                        sx={{ justifyContent: "flex-start" }}
                        onClick={handleAddContract}
                    >
                        <FormattedMessage defaultMessage="Add contract" description="Add document button" />
                    </Button>
                </NoPermissionTooltip>
            </Stack>
            <AllContractsModal
                contracts={contracts}
                open={openViewAll}
                onClose={() => setOpenViewAll(false)}
                isEditor={isEditor}
                onAddContract={handleAddContract}
            />
        </Stack>
    );
};
