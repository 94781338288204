import { useQuery } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql/gql";
import { SupplierCustomFieldType, SupplierInfo_SupplierFragment } from "@/gql/graphql";
import { track } from "@/lib/track";

import { AboutSupplierModal } from "./AboutSupplierModal";
import { industryId, InfoRow, isValidDate } from "./utils";

graphql(`
    fragment SupplierInfo_Supplier on Supplier {
        id
        name
        country
        orgNumber
        nace
        onboarding {
            status
            approverId
            evaluatedAt
        }
        ...AboutSupplierModal_Supplier
    }

    fragment SupplierInfo_RoleUser on RoleUser {
        id
        firstName
        lastName
    }
`);

const getApproverUserQuery = graphql(`
    query getUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...SupplierInfo_RoleUser
            }
        }
    }
`);

interface SupplierInfoProps {
    supplier: SupplierInfo_SupplierFragment;
    isEditor: boolean;
}

export const SupplierInfo: React.FC<SupplierInfoProps> = ({ supplier, isEditor }) => {
    const [edit, setEdit] = useState(false);
    const { formatMessage } = useIntl();
    const [showAll, setShowAll] = useState(false);
    const [approver, setApprover] = useState("");
    const onboardingEnabled = useFeatureToggle("onboarding-enabled", true);
    useQuery(getApproverUserQuery, {
        variables: {
            input: {
                userIds: [supplier.onboarding?.approverId ?? ""],
            },
        },
        skip: !supplier.onboarding?.approverId || supplier.onboarding?.approverId === "",
        onCompleted: (data) => {
            if (data.getUsers.result.length !== 1) return;
            setApprover(`${data.getUsers.result[0].firstName} ${data.getUsers.result[0].lastName}`);
        },
    });

    const riskValues = supplier.customFields.filter((field) => field.fieldType === SupplierCustomFieldType.Risk);
    const onboardingDate = supplier.onboarding?.evaluatedAt ?? "";
    const isValidOnboardingDate = isValidDate(onboardingDate);

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="About" description="supplier information header" />
                        </Typography>
                        <Button
                            type="button"
                            color="secondary"
                            size="small"
                            onClick={() => {
                                track("Supplier Profile: Edit Supplier Info Opened");
                                setEdit(true);
                                setShowAll((prev) => !prev);
                            }}
                        >
                            <FormattedMessage defaultMessage="Edit" description="Edit button" />
                        </Button>
                    </Stack>
                }
            />
            <CardContent sx={{ paddingTop: 0 }}>
                <Stack spacing={2}>
                    <Grid container rowSpacing={2}>
                        <InfoRow
                            name={formatMessage({ defaultMessage: "Country", description: "Country name" })}
                            value={supplier.country ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                        <InfoRow
                            id={industryId}
                            name={formatMessage({ defaultMessage: "Industry", description: "Industry name" })}
                            value={supplier.nace ?? ""}
                            type={SupplierCustomFieldType.Nace}
                        />
                        <InfoRow
                            name={formatMessage({ defaultMessage: "VAT id", description: "VAT id name" })}
                            value={supplier.orgNumber ?? ""}
                            type={SupplierCustomFieldType.Text}
                        />
                    </Grid>
                    <Divider />
                    {supplier.onboarding && onboardingEnabled && (
                        <>
                            <Grid container rowSpacing={2}>
                                <InfoRow
                                    name={formatMessage({ defaultMessage: "Approver", description: "Approver name" })}
                                    value={
                                        approver
                                            ? approver
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approver placeholder",
                                              })
                                    }
                                    type={SupplierCustomFieldType.Text}
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Approved at",
                                        description: "Approved at name",
                                    })}
                                    value={
                                        isValidOnboardingDate
                                            ? onboardingDate
                                            : formatMessage({
                                                  defaultMessage: "N/A",
                                                  description: "Onboarding approved at placeholder",
                                              })
                                    }
                                    type={
                                        isValidOnboardingDate
                                            ? SupplierCustomFieldType.Date
                                            : SupplierCustomFieldType.Text
                                    }
                                />
                                <InfoRow
                                    name={formatMessage({
                                        defaultMessage: "Onboarding status",
                                        description: "Onboarding status name",
                                    })}
                                    value={supplier.onboarding.status}
                                    type="onboarding"
                                />
                            </Grid>
                            <Divider />
                        </>
                    )}
                    <Grid container rowSpacing={2}>
                        <InfoRow name="NACE" value={supplier.nace ?? ""} type={SupplierCustomFieldType.Nace} />
                        {riskValues.map((field) => {
                            const value = JSON.parse(field.dataJson);
                            return <InfoRow key={field.name} name={field.name} value={value} type={field.fieldType} />;
                        })}
                    </Grid>
                </Stack>
            </CardContent>
            <CardActions>
                <Stack width="100%">
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => {
                            track("Supplier Profile: Show More Supplier Info Opened");
                            setShowAll((prev) => !prev);
                        }}
                    >
                        <FormattedMessage defaultMessage="Show more" description="Show more button supplier info" />
                    </Button>
                </Stack>
            </CardActions>
            <AboutSupplierModal
                open={showAll}
                onClose={() => {
                    setEdit(false);
                    setShowAll(false);
                }}
                supplier={supplier}
                approver={approver}
                isEditor={isEditor}
                edit={edit}
                setEdit={setEdit}
                onboardingEnabled={onboardingEnabled}
            />
        </Card>
    );
};
