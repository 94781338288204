import { ReactNode, createContext, useContext, useState } from "react";

type Props = {
    children: ReactNode;
    startPolling: (pollingInterval: number) => void;
    stopPolling: () => void;
};

interface ContextValue {
    handleStartPolling: (pollingInterval: number, timeout?: number) => void;
    handleStopPolling: () => void;
    isPolling: boolean;
}

export const pollingContext = createContext<ContextValue | null>(null);

export const PollingContextProvider: React.FC<Props> = ({ children, startPolling, stopPolling }) => {
    const [isPolling, setIsPolling] = useState<boolean>(false);

    const handleStartPolling = (pollingInterval: number, timeout?: number) => {
        if (isPolling) {
            console.warn("polling is already active");
            return;
        }
        setIsPolling(true);
        startPolling(pollingInterval);
        if (timeout) {
            setTimeout(() => {
                handleStopPolling();
            }, timeout);
        }
    };

    const handleStopPolling = () => {
        setIsPolling(false);
        stopPolling();
    };

    return (
        <pollingContext.Provider value={{ handleStartPolling, handleStopPolling, isPolling }}>
            {children}
        </pollingContext.Provider>
    );
};

export const usePolling = (): {
    handleStartPolling: (pollingInterval: number, timeout?: number) => void;
    handleStopPolling: () => void;
    isPolling: boolean;
} => {
    const context = useContext(pollingContext);

    if (!context) {
        throw new Error("usePolling must be used within a PollingProvider");
    }

    const { handleStartPolling, handleStopPolling, isPolling } = context;

    return { handleStartPolling, handleStopPolling, isPolling };
};
