import { Box } from "@mui/material";

export const ProfileAvatar: React.FC<{ icon: React.ReactNode }> = ({ icon }) => {
    return (
        <Box
            sx={{
                border: "1px solid lightgray",
                width: "44px",
                height: "44px",
                borderRadius: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {icon}
        </Box>
    );
};
