import { Plus, X } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import { ContractItem_ContractFragment } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useOutgoingNavigate } from "@/providers/NavigationContext";

import { ContractItem } from "./ContractItem";

interface AllContractsModalProps {
    contracts: ContractItem_ContractFragment[];
    open: boolean;
    onClose: () => void;
    isEditor: boolean;
    onAddContract: () => void;
}

export const AllContractsModal: React.FC<AllContractsModalProps> = ({
    contracts,
    open,
    onClose,
    isEditor,
    onAddContract,
}) => {
    const navigate = useOutgoingNavigate();
    const handleNavigateToContracts = () => {
        track("Supplier Profile: Navigated to All Contracts");
        navigate("/contracts/list?status=all");
    };

    const contractsByYear = contracts.reduce(
        (acc, contract) => {
            const year = contract.endDate
                ? new Date(contract.endDate).getFullYear().toString()
                : new Date().getFullYear().toString();

            if (!acc[year]) {
                acc[year] = [];
            }

            acc[year].push(contract);
            acc[year].sort((a, b) => {
                const endDateA = a.endDate ? new Date(a.endDate).getTime() : 0;
                const endDateB = b.endDate ? new Date(b.endDate).getTime() : 0;
                return endDateB - endDateA;
            });
            return acc;
        },
        {} as Record<string, ContractItem_ContractFragment[]>
    );

    const sortedContracts = Object.entries(contractsByYear).sort(([yearA], [yearB]) => Number(yearB) - Number(yearA));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                    width: "700px",
                },
            }}
            maxWidth="lg"
        >
            <DialogTitle>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography variant="textXl">
                        <FormattedMessage defaultMessage="Contracts" description="All contracts header" />
                    </Typography>
                    <Button color="secondary" size="xsmall" onClick={handleNavigateToContracts}>
                        <FormattedMessage defaultMessage="Open in Contracts" description="Open in Contracts button" />
                    </Button>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} paddingTop={4}>
                    {sortedContracts.map(([year, contracts]) => (
                        <Stack key={year} spacing={2}>
                            <Typography variant="textSm">{year}</Typography>
                            {contracts.map((contract: ContractItem_ContractFragment) => (
                                <ContractItem key={contract.id} contract={contract} />
                            ))}
                        </Stack>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Button
                        component="label"
                        disabled={!isEditor}
                        startIcon={<Plus />}
                        size="small"
                        color="linkPrimary"
                        sx={{ justifyContent: "flex-start" }}
                        onClick={onAddContract}
                    >
                        <FormattedMessage defaultMessage="Add contract" description="Add document button" />
                    </Button>
                    <Button color="secondary" onClick={onClose} size="xsmall">
                        <FormattedMessage defaultMessage="Close" description="Close button" />
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
