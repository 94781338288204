import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, IconButton, InputAdornment, Slide, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ActivityBar_SupplierFragment, Activity_NoteFragmentDoc } from "@/gql/graphql";
import { usePermission } from "@/lib/permissions";

import { Activities } from "./Activities";

const createNoteDocument = graphql(`
    mutation Notes_CreateNoteMutation($input: CreateSupplierNoteInput!) {
        createSupplierNote(input: $input) {
            note {
                ...Activity_Note
            }
        }
    }
`);

export const ActivityBar: React.FC<{
    open: boolean;
    handleChange: () => void;
    supplier: ActivityBar_SupplierFragment;
}> = ({ open, handleChange, supplier }) => {
    const { formatMessage } = useIntl();
    const [newNote, setNewNote] = useState("");

    const [createNote] = useMutation(createNoteDocument, {
        variables: { input: { supplierId: supplier.id, note: newNote } },
        refetchQueries: ["AppRoutes_GetSupplier"],
        update: (cache, { data }) => {
            if (!data?.createSupplierNote?.note) {
                return;
            }
            cache.modify({
                id: cache.identify(supplier),
                fields: {
                    notes(existingNotes = []) {
                        const newNoteRef = cache.writeFragment({
                            data: data.createSupplierNote.note,
                            fragment: Activity_NoteFragmentDoc,
                            fragmentName: "Activity_Note",
                        });
                        return [newNoteRef, ...existingNotes];
                    },
                },
            });
        },
        onCompleted: () => {
            setNewNote("");
            track("Supplier Profile: Created note", { supplierId: supplier.id });
        },
    });

    const isEditor = usePermission({ object: "general", relation: "write" });
    return (
        <Stack
            sx={{
                position: "fixed",
                right: (theme) => theme.spacing(3),
                flexGrow: 1,
                width: "clamp(300px, (100%) * 0.26, 490px)",
                height: "min(99vh - 178px, 99%)",
                zIndex: (theme) => theme.zIndex.drawer,
                top: `178px`,
            }}
        >
            <Stack
                sx={{
                    height: "100%",
                }}
            >
                <Slide direction="left" in={open} mountOnEnter unmountOnExit>
                    <Stack
                        sx={{
                            borderRadius: 1,
                            border: "1px solid #C9CDD2",
                            right: 0,
                            background: (theme) => theme.palette.background.backgroundSecondary,
                            position: "absolute",
                            boxShadow: (theme) => theme.shadows[6],
                            width: "100%",
                            height: "100%",
                        }}
                        flexGrow={1}
                    >
                        <Stack alignItems="end">
                            <IconButton onClick={handleChange}>
                                <X />
                            </IconButton>
                        </Stack>
                        <Stack
                            gap={2}
                            flexGrow={1}
                            overflow="auto"
                            top="30px"
                            flexDirection="column-reverse"
                            paddingRight={3}
                            paddingLeft={2}
                            paddingBottom={2}
                        >
                            <Activities supplier={supplier} isEditor={isEditor} />
                        </Stack>
                        {isEditor && (
                            <Stack paddingRight={3}>
                                <TextField
                                    placeholder={formatMessage({ defaultMessage: "Leave a note" })}
                                    multiline
                                    value={newNote}
                                    onChange={(e) => setNewNote(e.target.value)}
                                    maxRows={20}
                                    variant="outlined"
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        "& .MuiInputBase-input": {
                                            backgroundColor: "white",
                                        },
                                        "& .MuiInputBase-multiline textarea": {
                                            paddingY: "9px",
                                        },
                                    }}
                                    // create note on enter - new line on shift + enter
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter" && !e.shiftKey && newNote) {
                                            track("Supplier Profile: Created Note On With Keyboard Shortcut");
                                            e.preventDefault();
                                            createNote();
                                        }
                                        if (e.key === "Enter" && e.shiftKey) {
                                            setNewNote(newNote + "\n");
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            paddingX: 2,
                                            paddingY: 1.5,
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    disabled={!newNote}
                                                    onClick={() => createNote()}
                                                >
                                                    {formatMessage({ defaultMessage: "Post" })}
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Slide>
            </Stack>
        </Stack>
    );
};
