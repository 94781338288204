export const countryCodes = [
    { code: "AF", name: "Afghanistan", norwegian: "Afghanistan" },
    { code: "AL", name: "Albania", norwegian: "Albania" },
    { code: "DZ", name: "Algeria", norwegian: "Algerie" },
    { code: "AS", name: "American Samoa", norwegian: "Amerikansk Samoa" },
    { code: "AD", name: "Andorra", norwegian: "Andorra" },
    { code: "AO", name: "Angola", norwegian: "Angola" },
    { code: "AI", name: "Anguilla", norwegian: "Anguilla" },
    { code: "AQ", name: "Antarctica", norwegian: "Antarktis" },
    { code: "AG", name: "Antigua and Barbuda", norwegian: "Antigua og Barbuda" },
    { code: "AR", name: "Argentina", norwegian: "Argentina" },
    { code: "AM", name: "Armenia", norwegian: "Armenia" },
    { code: "AW", name: "Aruba", norwegian: "Aruba" },
    { code: "AU", name: "Australia", norwegian: "Australia" },
    { code: "AT", name: "Austria", norwegian: "Østerrike" },
    { code: "AZ", name: "Azerbaijan", norwegian: "Aserbajdsjan" },
    { code: "BS", name: "Bahamas", norwegian: "Bahamas" },
    { code: "BH", name: "Bahrain", norwegian: "Bahrain" },
    { code: "BD", name: "Bangladesh", norwegian: "Bangladesh" },
    { code: "BB", name: "Barbados", norwegian: "Barbados" },
    { code: "BY", name: "Belarus", norwegian: "Hviterussland" },
    { code: "BE", name: "Belgium", norwegian: "Belgia" },
    { code: "BZ", name: "Belize", norwegian: "Belize" },
    { code: "BJ", name: "Benin", norwegian: "Benin" },
    { code: "BM", name: "Bermuda", norwegian: "Bermuda" },
    { code: "BT", name: "Bhutan", norwegian: "Bhutan" },
    { code: "BO", name: "Bolivia", norwegian: "Bolivia" },
    { code: "BQ", name: "Bonaire, Sint Eustatius and Saba", norwegian: "Bonaire, Sint Eustatius og Saba" },
    { code: "BA", name: "Bosnia and Herzegovina", norwegian: "Bosnia-Hercegovina" },
    { code: "BW", name: "Botswana", norwegian: "Botswana" },
    { code: "BV", name: "Bouvet Island", norwegian: "Bouvetøya" },
    { code: "BR", name: "Brazil", norwegian: "Brasil" },
    { code: "IO", name: "British Indian Ocean Territory", norwegian: "Britiske territorier i Indiahavet" },
    { code: "BN", name: "Brunei Darussalam", norwegian: "Brunei Darussalam" },
    { code: "BG", name: "Bulgaria", norwegian: "Bulgaria" },
    { code: "BF", name: "Burkina Faso", norwegian: "Burkina Faso" },
    { code: "BI", name: "Burundi", norwegian: "Burundi" },
    { code: "CV", name: "Cabo Verde", norwegian: "Kapp Verde" },
    { code: "KH", name: "Cambodia", norwegian: "Kambodsja" },
    { code: "CM", name: "Cameroon", norwegian: "Kamerun" },
    { code: "CA", name: "Canada", norwegian: "Canada" },
    { code: "KY", name: "Cayman Islands", norwegian: "Caymanøyene" },
    { code: "CF", name: "Central African Republic", norwegian: "Den sentralafrikanske republikk" },
    { code: "TD", name: "Chad", norwegian: "Tsjad" },
    { code: "CL", name: "Chile", norwegian: "Chile" },
    { code: "CN", name: "China", norwegian: "Kina" },
    { code: "CX", name: "Christmas Island", norwegian: "Christmasøya" },
    { code: "CC", name: "Cocos (Keeling) Islands", norwegian: "Kokosøyene" },
    { code: "CO", name: "Colombia", norwegian: "Colombia" },
    { code: "KM", name: "Comoros", norwegian: "Komorene" },
    { code: "CG", name: "Congo", norwegian: "Kongo" },
    { code: "CD", name: "Congo, Democratic Republic of the", norwegian: "Den demokratiske republikken Kongo" },
    { code: "CK", name: "Cook Islands", norwegian: "Cookøyene" },
    { code: "CR", name: "Costa Rica", norwegian: "Costa Rica" },
    { code: "HR", name: "Croatia", norwegian: "Kroatia" },
    { code: "CU", name: "Cuba", norwegian: "Cuba" },
    { code: "CW", name: "Curaçao", norwegian: "Curaçao" },
    { code: "CY", name: "Cyprus", norwegian: "Kypros" },
    { code: "CZ", name: "Czechia", norwegian: "Tsjekkia" },
    { code: "CI", name: "Côte d'Ivoire", norwegian: "Elfenbenskysten" },
    { code: "DK", name: "Denmark", norwegian: "Danmark" },
    { code: "DJ", name: "Djibouti", norwegian: "Djibouti" },
    { code: "DM", name: "Dominica", norwegian: "Dominica" },
    { code: "DO", name: "Dominican Republic", norwegian: "Den dominikanske republikk" },
    { code: "EC", name: "Ecuador", norwegian: "Ecuador" },
    { code: "EG", name: "Egypt", norwegian: "Egypt" },
    { code: "SV", name: "El Salvador", norwegian: "El Salvador" },
    { code: "GQ", name: "Equatorial Guinea", norwegian: "Ekvatorial-Guinea" },
    { code: "ER", name: "Eritrea", norwegian: "Eritrea" },
    { code: "EE", name: "Estonia", norwegian: "Estland" },
    { code: "SZ", name: "Eswatini", norwegian: "Eswatini" },
    { code: "ET", name: "Ethiopia", norwegian: "Etiopia" },
    { code: "FK", name: "Falkland Islands (Malvinas)", norwegian: "Falklandsøyene" },
    { code: "FO", name: "Faroe Islands", norwegian: "Færøyene" },
    { code: "FJ", name: "Fiji", norwegian: "Fiji" },
    { code: "FI", name: "Finland", norwegian: "Finland" },
    { code: "FR", name: "France", norwegian: "Frankrike" },
    { code: "GF", name: "French Guiana", norwegian: "Fransk Guyana" },
    { code: "PF", name: "French Polynesia", norwegian: "Fransk Polynesia" },
    { code: "TF", name: "French Southern Territories", norwegian: "Franske sørterritorier" },
    { code: "GA", name: "Gabon", norwegian: "Gabon" },
    { code: "GM", name: "Gambia", norwegian: "Gambia" },
    { code: "GE", name: "Georgia", norwegian: "Georgia" },
    { code: "DE", name: "Germany", norwegian: "Tyskland" },
    { code: "GH", name: "Ghana", norwegian: "Ghana" },
    { code: "GI", name: "Gibraltar", norwegian: "Gibraltar" },
    { code: "GR", name: "Greece", norwegian: "Hellas" },
    { code: "GL", name: "Greenland", norwegian: "Grønland" },
    { code: "GD", name: "Grenada", norwegian: "Grenada" },
    { code: "GP", name: "Guadeloupe", norwegian: "Guadeloupe" },
    { code: "GU", name: "Guam", norwegian: "Guam" },
    { code: "GT", name: "Guatemala", norwegian: "Guatemala" },
    { code: "GG", name: "Guernsey", norwegian: "Guernsey" },
    { code: "GN", name: "Guinea", norwegian: "Guinea" },
    { code: "GW", name: "Guinea-Bissau", norwegian: "Guinea-Bissau" },
    { code: "GY", name: "Guyana", norwegian: "Guyana" },
    { code: "HT", name: "Haiti", norwegian: "Haiti" },
    { code: "HM", name: "Heard Island and McDonald Islands", norwegian: "Heard- og McDonaldøyene" },
    { code: "VA", name: "Holy See", norwegian: "Vatikanstaten" },
    { code: "HN", name: "Honduras", norwegian: "Honduras" },
    { code: "HK", name: "Hong Kong", norwegian: "Hongkong" },
    { code: "HU", name: "Hungary", norwegian: "Ungarn" },
    { code: "IS", name: "Iceland", norwegian: "Island" },
    { code: "IN", name: "India", norwegian: "India" },
    { code: "ID", name: "Indonesia", norwegian: "Indonesia" },
    { code: "IR", name: "Iran, Islamic Republic of", norwegian: "Iran" },
    { code: "IQ", name: "Iraq", norwegian: "Irak" },
    { code: "IE", name: "Ireland", norwegian: "Irland" },
    { code: "IM", name: "Isle of Mann", norwegian: "Man" },
    { code: "IL", name: "Israel", norwegian: "Israel" },
    { code: "IT", name: "Italy", norwegian: "Italia" },
    { code: "JM", name: "Jamaica", norwegian: "Jamaica" },
    { code: "JP", name: "Japan", norwegian: "Japan" },
    { code: "JE", name: "Jersey", norwegian: "Jersey" },
    { code: "JO", name: "Jordan", norwegian: "Jordan" },
    { code: "KZ", name: "Kazakhstan", norwegian: "Kasakhstan" },
    { code: "KE", name: "Kenya", norwegian: "Kenya" },
    { code: "KI", name: "Kiribati", norwegian: "Kiribati" },
    { code: "KP", name: "Korea, Democratic People's Republic of", norwegian: "Nord-Korea" },
    { code: "KR", name: "Korea, Republic of", norwegian: "Sør-Korea" },
    { code: "KW", name: "Kuwait", norwegian: "Kuwait" },
    { code: "KG", name: "Kyrgyzstan", norwegian: "Kirgisistan" },
    { code: "LA", name: "Lao People's Democratic Republic", norwegian: "Laos" },
    { code: "LV", name: "Latvia", norwegian: "Latvia" },
    { code: "LB", name: "Lebanon", norwegian: "Libanon" },
    { code: "LS", name: "Lesotho", norwegian: "Lesotho" },
    { code: "LR", name: "Liberia", norwegian: "Liberia" },
    { code: "LY", name: "Libya", norwegian: "Libya" },
    { code: "LI", name: "Liechtenstein", norwegian: "Liechtenstein" },
    { code: "LT", name: "Lithuania", norwegian: "Litauen" },
    { code: "LU", name: "Luxembourg", norwegian: "Luxemburg" },
    { code: "MO", name: "Macao", norwegian: "Macao" },
    { code: "MG", name: "Madagascar", norwegian: "Madagaskar" },
    { code: "MW", name: "Malawi", norwegian: "Malawi" },
    { code: "MY", name: "Malaysia", norwegian: "Malaysia" },
    { code: "MV", name: "Maldives", norwegian: "Maldivene" },
    { code: "ML", name: "Mali", norwegian: "Mali" },
    { code: "MT", name: "Malta", norwegian: "Malta" },
    { code: "MH", name: "Marshall Islands", norwegian: "Marshalløyene" },
    { code: "MQ", name: "Martinique", norwegian: "Martinique" },
    { code: "MR", name: "Mauritania", norwegian: "Mauritania" },
    { code: "MU", name: "Mauritius", norwegian: "Mauritius" },
    { code: "YT", name: "Mayotte", norwegian: "Mayotte" },
    { code: "MX", name: "Mexico", norwegian: "Mexico" },
    { code: "FM", name: "Micronesia (Federated States of)", norwegian: "Mikronesiaføderasjonen" },
    { code: "MD", name: "Moldova, Republic of", norwegian: "Moldova" },
    { code: "MC", name: "Monaco", norwegian: "Monaco" },
    { code: "MN", name: "Mongolia", norwegian: "Mongolia" },
    { code: "ME", name: "Montenegro", norwegian: "Montenegro" },
    { code: "MS", name: "Montserrat", norwegian: "Montserrat" },
    { code: "MA", name: "Morocco", norwegian: "Marokko" },
    { code: "MZ", name: "Mozambique", norwegian: "Mosambik" },
    { code: "MM", name: "Myanmar", norwegian: "Myanmar" },
    { code: "NA", name: "Namibia", norwegian: "Namibia" },
    { code: "NR", name: "Nauru", norwegian: "Nauru" },
    { code: "NP", name: "Nepal", norwegian: "Nepal" },
    { code: "NL", name: "Netherlands", norwegian: "Nederland" },
    { code: "NC", name: "New Caledonia", norwegian: "Ny-Caledonia" },
    { code: "NZ", name: "New Zealand", norwegian: "New Zealand" },
    { code: "NI", name: "Nicaragua", norwegian: "Nicaragua" },
    { code: "NE", name: "Niger", norwegian: "Niger" },
    { code: "NG", name: "Nigeria", norwegian: "Nigeria" },
    { code: "NU", name: "Niue", norwegian: "Niue" },
    { code: "NF", name: "Norfolk Island", norwegian: "Norfolkøya" },
    { code: "MP", name: "Northern Mariana Islands", norwegian: "Nord-Marianene" },
    { code: "NO", name: "Norway", norwegian: "Norge" },
    { code: "OM", name: "Oman", norwegian: "Oman" },
    { code: "PK", name: "Pakistan", norwegian: "Pakistan" },
    { code: "PW", name: "Palau", norwegian: "Palau" },
    { code: "PS", name: "Palestine, State of", norwegian: "Palestina" },
    { code: "PA", name: "Panama", norwegian: "Panama" },
    { code: "PG", name: "Papua New Guinea", norwegian: "Papua Ny-Guinea" },
    { code: "PY", name: "Paraguay", norwegian: "Paraguay" },
    { code: "PE", name: "Peru", norwegian: "Peru" },
    { code: "PH", name: "Philippines", norwegian: "Filippinene" },
    { code: "PN", name: "Pitcairn", norwegian: "Pitcairnøyene" },
    { code: "PL", name: "Poland", norwegian: "Polen" },
    { code: "PT", name: "Portugal", norwegian: "Portugal" },
    { code: "PR", name: "Puerto Rico", norwegian: "Puerto Rico" },
    { code: "QA", name: "Qatar", norwegian: "Qatar" },
    { code: "RE", name: "Réunion", norwegian: "Réunion" },
    { code: "RO", name: "Romania", norwegian: "Romania" },
    { code: "RU", name: "Russian Federation", norwegian: "Russland" },
    { code: "RW", name: "Rwanda", norwegian: "Rwanda" },
    { code: "BL", name: "Saint Barthélemy", norwegian: "Saint-Barthélemy" },
    { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha", norwegian: "St. Helena" },
    { code: "KN", name: "Saint Kitts and Nevis", norwegian: "St. Kitts og Nevis" },
    { code: "LC", name: "Saint Lucia", norwegian: "St. Lucia" },
    { code: "MF", name: "Saint Martin (French part)", norwegian: "Saint-Martin" },
    { code: "PM", name: "Saint Pierre and Miquelon", norwegian: "Saint-Pierre-et-Miquelon" },
    { code: "VC", name: "Saint Vincent and the Grenadines", norwegian: "St. Vincent og Grenadinene" },
    { code: "WS", name: "Samoa", norwegian: "Samoa" },
    { code: "SM", name: "San Marino", norwegian: "San Marino" },
    { code: "ST", name: "Sao Tome and Principe", norwegian: "São Tomé og Príncipe" },
    { code: "SA", name: "Saudi Arabia", norwegian: "Saudi-Arabia" },
    { code: "SN", name: "Senegal", norwegian: "Senegal" },
    { code: "RS", name: "Serbia", norwegian: "Serbia" },
    { code: "SC", name: "Seychelles", norwegian: "Seychellene" },
    { code: "SL", name: "Sierra Leone", norwegian: "Sierra Leone" },
    { code: "SG", name: "Singapore", norwegian: "Singapore" },
    { code: "SX", name: "Sint Maarten (Dutch part)", norwegian: "Sint Maarten" },
    { code: "SK", name: "Slovakia", norwegian: "Slovakia" },
    { code: "SI", name: "Slovenia", norwegian: "Slovenia" },
    { code: "SB", name: "Solomon Islands", norwegian: "Salomonøyene" },
    { code: "SO", name: "Somalia", norwegian: "Somalia" },
    { code: "ZA", name: "South Africa", norwegian: "Sør-Afrika" },
    { code: "GS", name: "South Georgia and the South Sandwich Islands", norwegian: "Sør-Georgia og Sør-Sandwichøyene" },
    { code: "SS", name: "South Sudan", norwegian: "Sør-Sudan" },
    { code: "ES", name: "Spain", norwegian: "Spania" },
    { code: "LK", name: "Sri Lanka", norwegian: "Sri Lanka" },
    { code: "SD", name: "Sudan", norwegian: "Sudan" },
    { code: "SR", name: "Suriname", norwegian: "Surinam" },
    { code: "SJ", name: "Svalbard and Jan Mayen", norwegian: "Svalbard og Jan Mayen" },
    { code: "SE", name: "Sweden", norwegian: "Sverige" },
    { code: "CH", name: "Switzerland", norwegian: "Sveits" },
    { code: "SY", name: "Syrian Arab Republic", norwegian: "Syria" },
    { code: "TW", name: "Taiwan", norwegian: "Taiwan" },
    { code: "TJ", name: "Tajikistan", norwegian: "Tadsjikistan" },
    { code: "TZ", name: "Tanzania, United Republic of", norwegian: "Tanzania" },
    { code: "TH", name: "Thailand", norwegian: "Thailand" },
    { code: "TL", name: "Timor-Leste", norwegian: "Øst-Timor" },
    { code: "TG", name: "Togo", norwegian: "Togo" },
    { code: "TK", name: "Tokelau", norwegian: "Tokelau" },
    { code: "TO", name: "Tonga", norwegian: "Tonga" },
    { code: "TT", name: "Trinidad and Tobago", norwegian: "Trinidad og Tobago" },
    { code: "TN", name: "Tunisia", norwegian: "Tunisia" },
    { code: "TR", name: "Turkey", norwegian: "Tyrkia" },
    { code: "TM", name: "Turkmenistan", norwegian: "Turkmenistan" },
    { code: "TC", name: "Turks and Caicos Islands", norwegian: "Turks- og Caicosøyene" },
    { code: "TV", name: "Tuvalu", norwegian: "Tuvalu" },
    { code: "UG", name: "Uganda", norwegian: "Uganda" },
    { code: "UA", name: "Ukraine", norwegian: "Ukraina" },
    { code: "AE", name: "United Arab Emirates", norwegian: "De forente arabiske emirater" },
    { code: "GB", name: "United Kingdom of Great Britain and Northern Ireland", norwegian: "Storbritannia" },
    { code: "US", name: "United States of America", norwegian: "USA" },
    { code: "UY", name: "Uruguay", norwegian: "Uruguay" },
    { code: "UZ", name: "Uzbekistan", norwegian: "Usbekistan" },
    { code: "VU", name: "Vanuatu", norwegian: "Vanuatu" },
    { code: "VE", name: "Venezuela", norwegian: "Venezuela" },
    { code: "VN", name: "Viet Nam", norwegian: "Vietnam" },
    { code: "VG", name: "Virgin Islands (British)", norwegian: "De britiske jomfruøyene" },
    { code: "VI", name: "Virgin Islands (U.S.)", norwegian: "De amerikanske jomfruøyene" },
    { code: "WF", name: "Wallis and Futuna", norwegian: "Wallis- og Futunaøyene" },
    { code: "EH", name: "Western Sahara", norwegian: "Vest-Sahara" },
    { code: "YE", name: "Yemen", norwegian: "Jemen" },
    { code: "ZM", name: "Zambia", norwegian: "Zambia" },
    { code: "ZW", name: "Zimbabwe", norwegian: "Zimbabwe" },
];
