import { useQuery } from "@apollo/client";
import { Button, Card, CardContent, CardHeader, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { GraphEmptyState } from "@/assets/GraphEmptyState";
import {
    DatePeriodSelection,
    getStaticFromRelative,
    parseCategoryData,
    relativeDatePeriodOptions,
    toDatePeriodInput,
    useDatePeriodLabel,
} from "@/components/Common/spendUtils";
import { CategoryChart } from "@/components/Pages/Spend/CategoryChart";
import { SpendDevelopmentChart } from "@/components/Pages/Spend/SpendDevelopmentChart";
import { getMetricDevelopment, getSpendDevelopment } from "@/components/Pages/Spend/useSpendData";
import { formatSpend } from "@/components/Pages/Spend/utils";
import { DateInterval, PredefinedMetric, ProcurementEntityType } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useCompanyCurrency } from "@/providers/CompanyCurrencyContext";

interface SpendDevelopmentProps {
    supplierId: string;
}

export const SpendDevelopment: React.FC<SpendDevelopmentProps> = ({ supplierId }) => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const currency = useCompanyCurrency();
    const period: DatePeriodSelection = {
        static: getStaticFromRelative(relativeDatePeriodOptions.last5Years),
        interval: DateInterval.Year,
    };
    const thisPeriodText = useDatePeriodLabel(period);

    const { data: catData, loading: categoryLoading } = useQuery(getMetricDevelopment, {
        skip: supplierId === "",
        variables: {
            input: {
                filterSelection: {
                    period: toDatePeriodInput(period, false),
                    filters: {
                        suppliers: [supplierId],
                    },
                },
                interval: DateInterval.Year,
                groupBy: {
                    field: ProcurementEntityType.Category,
                },
                metrics: [PredefinedMetric.Spend, PredefinedMetric.TransactionCount],
            },
        },
    });
    const gotCategoryData = (catData?.getMetricDevelopmentQuery.intervalResults?.length ?? 0) > 0;

    const { data: spendDevelopmentData, loading: spendDevelopmentLoading } = useQuery(getSpendDevelopment, {
        skip: !supplierId || categoryLoading || gotCategoryData,
        variables: {
            input: {
                selection: {
                    period: toDatePeriodInput(period, false),
                    filters: {
                        suppliers: [supplierId ?? ""],
                    },
                },
                interval: period.interval,
            },
        },
    });

    const { categories, categoryData, totalSpend } = parseCategoryData(
        formatMessage,
        catData?.getMetricDevelopmentQuery.intervalResults
    );

    const parsedData =
        spendDevelopmentData?.getSpendDevelopment.thisPeriod.map((thisPeriod, index) => ({
            label: spendDevelopmentData?.getSpendDevelopment.labels[index],
            [thisPeriodText]: thisPeriod,
        })) ?? [];

    const total = spendDevelopmentData?.getSpendDevelopment.thisPeriod.reduce((acc, curr) => acc + curr, 0) ?? 0;

    return (
        <Card>
            <CardHeader
                title={
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack spacing={1}>
                            <Typography variant="textXl" fontWeight={500}>
                                <FormattedMessage defaultMessage="Spend development" />
                            </Typography>
                            <Typography variant="textSm" sx={{ opacity: 0.6 }}>
                                <FormattedMessage defaultMessage="Yearly by category" />
                            </Typography>
                        </Stack>
                        <Button
                            color="secondary"
                            size="xsmall"
                            onClick={() => {
                                track("Supplier Profile: Clicked Spend page");
                                navigate(`/supplier-profile/${supplierId}/spend`);
                            }}
                        >
                            <FormattedMessage defaultMessage="Go to Spend" />
                        </Button>
                    </Stack>
                }
            ></CardHeader>
            <CardContent>
                {categoryLoading || spendDevelopmentLoading ? (
                    <Skeleton height={376} />
                ) : !gotCategoryData &&
                  (!spendDevelopmentData || spendDevelopmentData.getSpendDevelopment.labels.length == 0) ? (
                    <Stack alignItems="center" justifyContent="center" height="376px" width="100%" spacing={2}>
                        <GraphEmptyState />
                        <Typography fontWeight={400} variant="textSm">
                            {formatMessage({ defaultMessage: "Found no spend during this period. " })}
                        </Typography>
                    </Stack>
                ) : gotCategoryData ? (
                    <CategoryChart
                        period={period}
                        currency={currency}
                        categoryData={categoryData}
                        categories={categories}
                    />
                ) : (
                    <SpendDevelopmentChart
                        period={period}
                        periodKey={thisPeriodText}
                        currency={currency}
                        spendDevelopmentData={parsedData}
                    />
                )}
                <Divider sx={{ my: 2 }} />
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <Typography variant="textMd" fontWeight={500}>
                        <FormattedMessage defaultMessage="Total spend" />
                    </Typography>
                    <Typography variant="textMd" fontWeight={700}>
                        {formatSpend(gotCategoryData ? totalSpend : total, false, currency)}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
