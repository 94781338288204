import { Stack } from "@mui/material";

import { graphql } from "@/gql";
import { SideColumn_SupplierFragment } from "@/gql/graphql";

import { SupplierContacts } from "./SupplierContacts/SupplierContacts";
import { SupplierInfo } from "./SupplierInfo/SupplierInfo";

graphql(`
    fragment SideColumn_Supplier on Supplier {
        ...SupplierInfo_Supplier
        ...SupplierContacts_Supplier
    }
`);

interface SideColumnProps {
    supplier: SideColumn_SupplierFragment;
    isEditor: boolean;
}

export const SideColumn: React.FC<SideColumnProps> = ({ supplier, isEditor }) => {
    return (
        <Stack spacing={3}>
            <SupplierInfo supplier={supplier} isEditor={isEditor} />
            <SupplierContacts supplier={supplier} isEditor={isEditor} />
        </Stack>
    );
};
