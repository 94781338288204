import { ChipProps } from "@mui/material";

export function calculateRisk(
    roce: number | undefined,
    currentRatio: number | undefined,
    equityRatio: number | undefined
) {
    let weightCount = 0;
    let parameterList: number[][] = [];

    if (roce) {
        weightCount += 1;
        const roceParams = [roce, -0.05, 0.15];
        parameterList = [...parameterList, roceParams];
    }
    if (currentRatio) {
        weightCount += 1;
        const currentRatioParams = [currentRatio, 0.5, 2];
        parameterList = [...parameterList, currentRatioParams];
    }
    if (equityRatio) {
        weightCount += 1;
        const equityRatioParams = [equityRatio, 0.03, 0.4];
        parameterList = [...parameterList, equityRatioParams];
    }
    if (weightCount === 0) {
        return undefined;
    }

    const n = 3;
    const min = 0.25;
    const max = 0.75;
    const weight = (max - (min - max / n) / (n - 1 / n)) / weightCount + ((min - max / n) / (n - 1 / n)) * weightCount;
    const risk =
        100 -
        parameterList.reduce((prev, curr) => {
            let mapV = ((curr[0] - curr[1]) * 100) / (curr[2] - curr[1]);
            mapV = mapV > 100 ? 100 : mapV;
            mapV = mapV < 0 ? 0 : mapV;
            mapV *= weight;
            return prev + mapV;
        }, 0);

    return Math.round(risk);
}

export const getColorFactory = (
    min: number,
    max: number,
    progress?: number,
    negativeColors?: boolean
): ChipProps["color"] => {
    if (!progress) return "info" as ChipProps["color"];
    if (progress > max) return (negativeColors ? "error" : "success") as ChipProps["color"];
    if (progress > min) return "warning" as ChipProps["color"];
    return (negativeColors ? "success" : "error") as ChipProps["color"];
};
