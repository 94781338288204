import { Chip, ChipProps, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { TooltipProps } from "recharts";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ValueChipProps extends TooltipProps<any, any> {
    value: number;
    label: string;
    color: ChipProps["color"];
    tooltipTitle?: string;
    tooltipText?: string;
}

export const PercentageChip: React.FC<ValueChipProps> = ({ value, label, color, tooltipTitle, tooltipText }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const valueString = value ? `${(value * 100).toFixed(0)}%` : "-";

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
            >
                <Chip
                    variant="status"
                    label={valueString}
                    color={color}
                    onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                    onMouseLeave={() => setAnchorEl(null)}
                />
                <Typography variant="textMd">{label}</Typography>
            </Stack>
            {(tooltipTitle || tooltipText) && (
                <Popover
                    open={anchorEl !== null}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    sx={{ mt: -1 }}
                    elevation={1}
                    style={{ pointerEvents: "none" }}
                >
                    <Stack padding={1} width={384} style={{ pointerEvents: "auto" }}>
                        {tooltipTitle && (
                            <Typography variant="textXs" fontWeight={600}>
                                {tooltipTitle}
                            </Typography>
                        )}
                        {tooltipText && (
                            <Typography variant="textXs" fontWeight={400}>
                                {tooltipText}
                            </Typography>
                        )}
                    </Stack>
                </Popover>
            )}
        </>
    );
};
