import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { track } from "@ignite-analytics/track";
import { LicenseInfo } from "@mui/x-license";
import React from "react";
import { NavigateFunction } from "react-router-dom";

import { AppRoutes } from "@/routes";

import { getUserInfo } from "./lib/token";

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_TOKEN);

type UserInfo = {
    email: string;
    tenant: string;
};

interface AppProps {
    navigate?: NavigateFunction;
}

const App: React.FC<AppProps> = ({ navigate }) => {
    const [userInfo, setUserInfo] = React.useState<UserInfo>({ email: "", tenant: "" });

    React.useEffect(() => {
        getUserInfo().then(({ email, tenant }) => {
            setUserInfo({ email, tenant });
        });
        track("Supplier Profile: Viewed Supplier");
    }, []);

    return (
        <FeatureToggleContextProvider userEmail={userInfo.email} tenant={userInfo.tenant}>
            <AppRoutes navigate={navigate} />
        </FeatureToggleContextProvider>
    );
};
export default App;
