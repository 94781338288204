import { CheckCircle, PlusCircle, XCircle } from "@ignite-analytics/icons";
import { Card, Grid, Stack, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useIntl } from "react-intl";

import { OnboardingStatus } from "@/gql/graphql";

import { normalizeLocale } from "./utils";

dayjs.extend(utc);
dayjs.extend(timezone);

export const OnboardingEvaluatedActivity: React.FC<{
    key: string;
    approvedBy?: string;
    evaluatedAt?: string | null;
    onboardingStatus?: OnboardingStatus;
    supplierName: string;
    reason?: string;
}> = ({ approvedBy, evaluatedAt, onboardingStatus, supplierName, reason }) => {
    const { formatMessage, locale, timeZone } = useIntl();
    dayjs.locale(normalizeLocale(locale));
    if (onboardingStatus === undefined || onboardingStatus === OnboardingStatus.InOnboarding || !approvedBy) {
        return;
    }

    const formatOnboardingActivityMessage = (status: OnboardingStatus | undefined) => {
        {
            switch (status) {
                case OnboardingStatus.Approved:
                    return formatMessage({ defaultMessage: "approved" });
                case OnboardingStatus.Rejected:
                    return formatMessage({ defaultMessage: "rejected" });
                default:
                    return "";
            }
        }
    };

    const actionString = ` ${formatOnboardingActivityMessage(onboardingStatus)} ${supplierName}.`;
    if (reason) {
        return (
            <Grid item>
                <Card sx={{ padding: 2 }}>
                    <Stack direction="row" spacing={1}>
                        <Stack alignItems="center" width={42}>
                            {onboardingStatus == OnboardingStatus.Approved ? (
                                <CheckCircle sx={{ color: (theme) => theme.palette.success.main }} />
                            ) : (
                                <XCircle sx={{ color: (theme) => theme.palette.error.main }} />
                            )}
                        </Stack>
                        <Stack>
                            <Typography variant="textSm">
                                <span style={{ fontWeight: 600 }}>{approvedBy + " "}</span>
                                <span style={{ fontWeight: 500 }}>{actionString}</span>
                            </Typography>
                            <Typography variant="textSm">
                                {formatMessage(
                                    {
                                        defaultMessage: ` {onboardingDate} ago`,
                                    },
                                    {
                                        onboardingDate: dayjs(evaluatedAt).utc().tz(timeZone).fromNow(true),
                                    }
                                )}
                            </Typography>
                            <Typography variant="textSm" color="text.secondary" marginTop={1}>
                                {reason}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        );
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginLeft: 1 }}>
            {onboardingStatus == OnboardingStatus.Approved ? (
                <CheckCircle sx={{ color: (theme) => theme.palette.success.main }} />
            ) : (
                <XCircle sx={{ color: (theme) => theme.palette.error.main }} />
            )}
            <Stack>
                <Typography variant="textSm">
                    <span style={{ fontWeight: 500 }}>{approvedBy + " "}</span>
                    {formatOnboardingActivityMessage(onboardingStatus) + " "}
                    <span style={{ fontWeight: 500 }}>{supplierName + ". "}</span>
                </Typography>
                <Typography variant="textSm" color="text.secondary">
                    {formatMessage(
                        {
                            defaultMessage: ` {onboardingDate} ago`,
                        },
                        {
                            onboardingDate: dayjs(evaluatedAt).utc().tz(timeZone).fromNow(true),
                        }
                    )}
                </Typography>
            </Stack>
        </Stack>
    );
};

export const OnboardingInitiatedActivity: React.FC<{ supplierName: string; createdAt?: string | null }> = ({
    supplierName,
    createdAt,
}) => {
    const { formatMessage, locale, timeZone } = useIntl();
    dayjs.locale(normalizeLocale(locale));
    const theme = useTheme();

    return (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ marginLeft: 1 }}>
            <PlusCircle color="secondary" />
            <Typography variant="textSm">
                <span style={{ fontWeight: 500 }}>{supplierName + " "}</span>
                {formatMessage({
                    defaultMessage: `was added for onboarding.`,
                }) + " "}
                <span style={{ color: theme.palette.text.secondary }}>
                    {formatMessage(
                        {
                            defaultMessage: ` {onboardingDate} ago`,
                        },
                        {
                            onboardingDate: dayjs(createdAt).utc().tz(timeZone).fromNow(true),
                        }
                    )}
                </span>
            </Typography>
        </Stack>
    );
};
