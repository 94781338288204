import { useQuery } from "@apollo/client/react/hooks/useQuery";
import { RefreshCcwClock } from "@ignite-analytics/icons";
import { Button, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { TruckIcon } from "@/assets/TruckIcon";
import { ProfileAvatar } from "@/components/Common/ProfileAvatar";
import { graphql } from "@/gql";
import { DateInterval, Masthead_SupplierFragment, PredefinedMetric, ProcurementEntityType } from "@/gql/graphql";
import { track } from "@/lib/track";

import useIsSmallScreen from "../hooks";

import { CategoryBar, CategoryEntity } from "./CategoryBar";

graphql(`
    fragment Masthead_Supplier on Supplier {
        id
        name
    }
    fragment ProcurementEntity on ProcurementEntityResult {
        entityId
        label
        distributions {
            procurementEntity
            elements {
                entityId
                label
                value
            }
        }
    }
`);

const GetProcurementEntities_ProfileQuery = graphql(`
    query SupplierProfile_GetCategories($input: GetProcurementEntityQueryInput!) {
        getProcurementEntityQuery(input: $input) {
            entities {
                ...ProcurementEntity
            }
        }
    }
`);

export const Masthead: React.FC<{
    handleActivityBar: () => void;
    supplier?: Masthead_SupplierFragment;
    loading: boolean;
}> = ({ handleActivityBar, supplier, loading }) => {
    const isSmallScreen = useIsSmallScreen();
    const { data } = useQuery(GetProcurementEntities_ProfileQuery, {
        variables: {
            input: {
                field: ProcurementEntityType.Supplier,
                filterSelection: {
                    filters: {
                        suppliers: [supplier?.id ?? ""], // we will not perform the query if supplier is not available
                    },
                    period: {
                        relative: {
                            length: 3,
                            offset: -1,
                            period: DateInterval.Year,
                        },
                    },
                },
                metrics: [PredefinedMetric.Spend],
                distributions: [
                    {
                        field: ProcurementEntityType.Category,
                    },
                    {
                        field: ProcurementEntityType.Department,
                    },
                ],
            },
        },
        skip: !supplier?.id,
    });

    const entities = data?.getProcurementEntityQuery.entities;
    const distributions =
        entities && entities.length > 0 ? data?.getProcurementEntityQuery.entities[0].distributions : [];

    const categoryEntities: CategoryEntity[] = distributions.flatMap((distribution) =>
        distribution.elements.map((element) => ({
            id: element.entityId,
            type: distribution.procurementEntity,
            label: element.label,
        }))
    );

    if (loading) {
        return <Skeleton variant="rectangular" height={125} />; // TODO: elaborate
    }

    if (!supplier) {
        return (
            <Typography>
                <FormattedMessage defaultMessage="Error loading supplier" description="Error loading supplier" />
            </Typography>
        );
    }

    const smallScreenHeader = () => (
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="textLg" fontWeight={700}>
                    {supplier.name}
                </Typography>
                <CategoryBar entities={categoryEntities} />
            </Stack>
            <IconButton
                onClick={() => {
                    handleActivityBar();
                    track("Supplier Profile: Opened Activity Bar With Button");
                }}
                color="secondary"
            >
                <RefreshCcwClock />
            </IconButton>
        </Stack>
    );

    return (
        <Stack direction="row" alignItems="center" gap={2} minHeight="44px">
            <ProfileAvatar icon={<TruckIcon />} />
            {isSmallScreen ? (
                smallScreenHeader()
            ) : (
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Stack direction="row" gap={1} alignItems="center">
                        <Typography variant="displayXs" fontWeight={700}>
                            {supplier.name}
                        </Typography>
                        <CategoryBar entities={categoryEntities} />
                    </Stack>

                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                            onClick={() => {
                                handleActivityBar();
                                track("Supplier Profile: Opened Activity Bar With Button");
                            }}
                            variant="text"
                            size="small"
                            color="secondary"
                            startIcon={<RefreshCcwClock />}
                        >
                            <FormattedMessage
                                defaultMessage="Notes and activity"
                                description="Notes and activity button"
                            />
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
